<template id="statistics-category">
  <div id="statistics-category" class="card card-custom gutter-b example example-compact">
    <div class="card-header">
      <h3 class="card-title">{{ this.name }}</h3>
    </div>
    <div class="card-body">
      <div id="hotelSearchForm" class="form">
        <div class="row mb-4">
          <div class="col-lg-3 mt-2">
            <label>{{ $t('Hotel', {value: 'Hotel'}) }}</label>
            <input type="text"
                   class="form-control"
                   autocomplete="off"
                   id="hotel"
                   name="hotel"
                   :placeholder="$t('Hotel name', {value: 'Hotel name'})"
                   maxlength="100"
                   value=""
                   required="required"
                   aria-required="true"
                   :data-msg-required="$t('Please select a hotel.', {value: 'Please select a hotel.'})"
                   :data-autocomplete="this.$envConfig.API_URL + '/autocomplete/accommodation'"
                   data-autocomplete-type="autocomplete"
                   data-autocomplete-method="POST"
                   data-autocomplete-response-data="accommodations"
                   data-autocomplete-columns="id,name"
                   data-autocomplete-label="#name#"
                   data-autocomplete-value="#id#"
                   data-autocomplete-target="#hotelId"
                   data-autocomplete-limit="10"
                   :data-autocomplete-message="$t('No matches found.', {value: 'No matches found.'})"
                   data-autocomplete-exclude-field="id"
                   data-autocomplete-exclude=""
            />
            <input type="hidden"
                   id="hotelId"
                   name="hotelId"
                   ref="hotelId"
                   data-search-key="hotelId"
                   data-search-type="="
                   data-type="number"
                   value=""/>
          </div>
          <div class="col-lg-3 mt-2">
            <label>{{ $t('Channel', {value: 'Channel'}) }}</label>
            <select data-search-key="channel"
                    name="channel"
                    id="show_channel"
                    data-search-type="="
                    ref="channel"
                    data-select="true"
                    data-select-params='{"allowClear": true, "placeholder":"All Channels","minimumResultsForSearch": -1}'
                    data-search-prop-key="true"
                    class="form-control m-input col-12">
              <option value="">{{ $t('All Channels', {value: 'All Channels'}) }}</option>
              <option v-for="channel in channels" :value="channel.name" :key="channel.id">
                {{ channel.name }}
              </option>
            </select>
          </div>
          <div class="col-lg-4  mt-2">
            <label>{{ $t('Date', {value: 'Date'}) }}</label>
            <div class="input-daterange input-group" id="m_datepicker">
              <input type="text" autocomplete="off" class="form-control m-input"
                     :placeholder="$t('After', {value: 'After'})"
                     name="fromDate"
                     id="fromDate"
                     ref="fromDate"
                     data-type="date"
                     data-date-to-date="#toDate"
                     data-search-key="fromDate"
                     data-search-type="="
              >
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="la la-ellipsis-h"></i>
                </span>
              </div>
              <input type="text" autocomplete="off" class="form-control m-input"
                     :placeholder="$t('Before', {value: 'Before'})"
                     name="toDate"
                     id="toDate"
                     ref="toDate"
                     data-type="date"
                     data-date-from-date="#fromDate"
                     data-search-key="toDate"
                     data-search-type="="
              >
            </div>
          </div>
          <div class="col-lg-2  mt-2 align-text-bottom lh-90">
            <button class="btn btn-secondary"
                    type="button"
                    name="searchBtn"
                    ref="searchBtn"
                    id="show_searchBtn"
                    data-button-type="search"
                    @click="updateGraph"
            >
              <i class="la la-search"></i>
              {{ $t('Search', {value: 'Search'}) }}
            </button>

          </div>
          <div class="col-lg-12">
            <div class="btn-group btn-group-sm" role="group">
              <a href="javascript:void(0)" type="button"
                 data-set-date="today"
                 @click="setDate('today')"
                 class="btn btn-outline-secondary">{{ $t('Today', {value: 'Today'}) }}</a>
              <a href="javascript:void(0)" type="button"
                 data-set-date="week"
                 @click="setDate('week')"
                 class="btn btn-outline-secondary">{{ $t('This Week', {value: 'This Week'}) }}</a>
              <a href="javascript:void(0)" type="button"
                 data-set-date="month"
                 @click="setDate('month')"
                 class="btn btn-outline-secondary">{{ $t('This Month', {value: 'This Month'}) }}</a>
              <a href="javascript:void(0)" type="button"
                 data-set-date="quarter"
                 @click="setDate('quarter')"
                 class="btn btn-outline-secondary">{{ $t('This Quarter', {value: 'This Quarter'}) }}</a>
              <a href="javascript:void(0)" type="button"
                 data-set-date="year"
                 @click="setDate('year')"
                 class="btn btn-outline-secondary">{{ $t('This Year', {value: 'This Year'}) }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-header">
      <h3 class="card-title">{{ this.dailyStatistics }}</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <apexchart width="100%"
                     height="600"
                     id="dailyStatics"
                     type="bar"
                     :options="dailyStatics.options"
                     :series="dailyStatics.series"></apexchart>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 h-520px">
          <channel-statistic :category="category"
                             :height="380"></channel-statistic>
        </div>
        <div class="col-12 col-md-6 h-520px">
          <donut-chart :label="perChannelReview"
                       :series="reviewPerChannel"
                       :dataLabels="channelList"
                       :height="450"></donut-chart>
        </div>
      </div>
    </div>

    <div class="card-header">
      <h3 class="card-title">{{ this.monthlyStatistics }}</h3>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <apexchart width="100%"
                     height="600px"
                     id="category"
                     type="area"
                     :options="monthlyStatics.options"
                     :series="monthlyStatics.series"></apexchart>
        </div>
      </div>

    </div>
  </div>
</template>
<style>
.h-520px,
.chart-height {
  height:450px;
}
</style>
<script>
import $ from "jquery";
import {coreJs} from "utilPath/coreJs";
import i18n from "corePath/plugins/vue-i18n";
import {_elementUtil} from "utilPath/element-util";
import {dateHelper} from "utilPath/dateHelper";
import {addLoader, removeLoader} from "../../../tools/src/core/util/helper";
import ChannelStatistic from "../content/charts/ChannelStatistic";
import DonutChart from "../content/charts/DonutChart";

export default {
  name: 'statisticsCategory',
  components: {ChannelStatistic, DonutChart},
  props: {
    category: {
      type: String,
      default: 'hotelScore'
    },
    name: {
      type: String,
      default: 'Hotel Statistics'
    }
  },
  data() {
    return {
      title: this.name,
      channel: null,
      hotelId: null,
      channels: [],
      channelList: [],
      dailyStatistics: this.$t('Daily Statistic', {value: "Daily Statistic"}),
      monthlyStatistics: this.$t('Monthly Statistic', {value: "Monthly Statistic"}),
      monthlyTalkedName: this.$t('Monthly Talked', {value: "Monthly Talked"}),
      perChannelReview: this.$t('Reviews Per Channel', {value: "Reviews Per Channel"}),
      monthlyStatics: {
        options: {
          chart: {
            id: 'monthlyStatics'
          },
          xaxis: {
            type: this.category,
            categories: ["A"]
          }
        },
        series: [
          {
            name: this.category,
            data: []
          }
        ],
        noData: {
          text: i18n.t('Loading', 'Loading...')
        }
      },
      reviewPerChannel: [],
      dailyStatics: {
        options: {
          chart: {
            id: 'dailyStatics',
            stacked: false,
            toolbar: {
              show: true
            },
          },
          zoom: {
            enabled: true
          },
          xaxis: {
            categories: []
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            }
          },
          dataLabels: {
            enabled: false
          },
          fill: {
            opacity: 1
          }
        },
        series: [
          {
            name: "Review Number",
            data: []
          },
          {
            name: "Review Score",
            data: []
          }
        ],
        noData: {
          text: i18n.t('Loading', 'Loading...')
        }
      },
    }
  },
  mounted() {
    coreJs.initContainer({container: '#statistics-category',name: this.name, title: this.title});

    this.getChannels();
    this.getDailyStatics();
    this.getMonthlyStatics();
    this.getReviewPerChannel();
  },
  updated() {

  },
  methods: {
    getChannels() {
      let module = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/channel/list', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({}),
        callBack: function (elt, response) {
          module.channels = response.data.channels;
        }
      });
    },
    setDailyStatics(response) {
      let series = [
        {
          name: "Review Number",
          data: []
        },
        {
          name: this.category,
          data: []
        }
      ];

      let dates = [];
      Object.entries(response.data.dailyStat).map((data) => {
        let date = dateHelper.dateFormat(data[0]);
        dates.push(date);
        series[0].data.push(data[1].reviews);
        series[1].data.push(data[1][this.category] !== null ? parseFloat(data[1][this.category].toFixed(2)) : 0);
      });

      this.dailyStatics.options = {
        ...this.dailyStatics.options, ...{
          xaxis: {
            categories: dates
          }
        }
      }

      this.dailyStatics.series = series;

      removeLoader(this.$refs.searchBtn);
    },
    getDailyStatics() {
      let _this = this;
      _elementUtil.postService(_this.$envConfig.API_URL + '/review/stats/daily', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          params: {
            channel: _this.channel,
            hotelId: _this.hotelId,
            fromDate: _this.fromDate,
            toDate: _this.toDate,
            category: _this.category
          }
        }),
        callBack: function (elt, response) {
          _this.setDailyStatics(response);
        }
      });
    },
    setReviewPerChannel(response) {
      let series = [];
      Object.entries(response.data.reviewStat).map((data) => {
        this.channelList.push(data[0]);
        series.push(data[1].reviews !== null ? data[1].reviews : 0);
      });

      this.reviewPerChannel = series;
    },
    getReviewPerChannel() {
      let _this = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/review/stats/channel', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          "params": {
            "fromDate": "2021-08-01",
            "toDate": "2021-12-31",
            "hotelId": null,
            "category": _this.category
          }
        }),
        callBack: function (elt, response) {
          _this.setReviewPerChannel(response);
        }
      });
    },
    setMonthlyStatics(response) {
      let categories = [];

      let series = [
        {
          name: this.category,
          data: []
        }
      ];
      let monthlySeries = [];
      let cnt = 0;
      Object.entries(response.data.monthlyStat).map((data) => {
        this.monthlyStatics.options.xaxis.categories[cnt] = data[0];


        cnt++;
        series[0].data.push(data[1][this.category] !== null ? data[1][this.category].toFixed(2) : 0);

      });
      this.monthlyStatics.series = series;
      removeLoader(this.$refs.searchBtn);
    },
    getMonthlyStatics() {
      let _this = this;
      _elementUtil.postService(this.$envConfig.API_URL + '/review/stats/monthly', {
        element: null,
        methodType: 'POST',
        dataType: 'JSON',
        data: JSON.stringify({
          params: {
            channel: _this.channel,
            hotelId: _this.hotelId,
            category: _this.category
          }
        }),
        callBack: function (elt, response) {
          _this.setMonthlyStatics(response);
        }
      });
    },
    updateGraph() {
      this.hotelId = this.$refs.hotelId.value !== "" ? parseInt(this.$refs.hotelId.value) : null;
      this.channel = this.$refs.channel.value !== "" ? this.$refs.channel.value : null;
      addLoader(this.$refs.searchBtn);
      this.getDailyStatics();
      this.getMonthlyStatics();
    },
    setDate(from) {
      let d = new Date();

      $('#hotelSearchForm').find('[data-set-date]').removeClass('btn-secondary').addClass('btn-outline-secondary');
      $('#hotelSearchForm').find('[data-set-date=' + from + ']').addClass('btn-secondary');
      if (from === 'today') {
        let d = new Date();
        this.$refs.fromDate.value = dateHelper.dateFormat(dateHelper.getDate(d));
        this.$refs.toDate.value = dateHelper.dateFormat(dateHelper.getDate(d));
      } else if (from === 'week') {
        let d = new Date();
        let first = d.getDate() - d.getDay();
        let last = first + 6;
        let firstDay = new Date(d.setDate(first + 1));
        let lastDay = new Date(d.setDate(last + 1));
        this.$refs.fromDate.value = dateHelper.dateFormat(dateHelper.getDate(firstDay));
        this.$refs.toDate.value = dateHelper.dateFormat(dateHelper.getDate(lastDay));
      } else if (from === 'month') {
        let d = new Date();
        let firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
        let lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        this.$refs.fromDate.value = dateHelper.dateFormat(dateHelper.getDate(firstDay));
        this.$refs.toDate.value = dateHelper.dateFormat(dateHelper.getDate(lastDay));
      } else if (from === 'quarter') {
        let d = new Date();
        let quarter = Math.floor((d.getMonth() / 3));
        let firstDay = new Date(d.getFullYear(), quarter * 3, 1);
        let lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 3, 0);
        this.$refs.fromDate.value = dateHelper.dateFormat(dateHelper.getDate(firstDay));
        this.$refs.toDate.value = dateHelper.dateFormat(dateHelper.getDate(lastDay));
      } else if (from === 'year') {
        let d = new Date();
        let firstDay = new Date(d.getFullYear(), 0, 1);
        let lastDay = new Date(d.getFullYear(), 12, 0);
        this.$refs.fromDate.value = dateHelper.dateFormat(dateHelper.getDate(firstDay));
        this.$refs.toDate.value = dateHelper.dateFormat(dateHelper.getDate(lastDay));
      }

      this.updateGraph();
    }
  },

};
</script>