export const dateHelper = {
    getDateFromString(date) {
        return new Date(date);
    },
    getDate(date) {
        return  date.getFullYear() + '-' +
            ((date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1)) + '-' +
            ((date.getDate() < 10 ? '0' : '') + date.getDate());
    },
    dateFormat: function (value) {
        let spl = value.split("-");
        return spl[2] + "/" + spl[1] + "/" + spl[0];
    },
    dateFormatStringToDb: function (value) {
        let spl = value.split("/");
        return spl[2] + "-" + spl[1] + "-" + spl[0];
    },
    getDay: function (date, days) {
        let dateObject = new Date(date);
        let index = -1;
        if (dateObject.getDay() === 0) {
            index = 6;
        } else {
            index = dateObject.getDay() - 1;
        }
        return days[index];
    },
    getDayNumber: function (index) {
        return parseInt(index) + 1;
    },
    col: function (date) {
        return date.getDate() < 10 ? 3 : 4;
    },
    day: function (date) {
        return date.getDate();
    },
    colDate: function (date) {
        return date.getDate() < 10 ? 9 : 8;
    },
    month: function (date, months) {
        return months[date.getMonth()] + "'" + date.getFullYear().toString().substring(2, 4);
    },
};

export default {dateHelper};